import React from "react";
import { useTranslation } from "react-i18next";
import QRCodeSVG from "qrcode.react";

import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ReactComponent as IconRelaxed } from "assets/icon-relaxed.svg";
import { ReactComponent as DownloadAndroid } from "assets/download-on-the-play-store.svg";
import { ReactComponent as DownloadApple } from "assets/download-on-the-app-store.svg";

import { useSearchParams } from "react-router-dom";
import SectionContainer from "./base/SectionContainer";

interface InvitationCodeProps {
  invitation_code?: string;
  family_invitation_code?: string;
}

function InvitationCard({
  invitation_code,
  family_invitation_code,
}: InvitationCodeProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const showSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const qr_apple = process.env.REACT_APP_APPLE_SOURCE as string;
  const qr_google = process.env.REACT_APP_GOOGLE_SOURCE as string;

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: 2,
        borderRadius: 4,
        mt: 4,
        overflow: "hidden",
      }}
    >
      {showSmUp && (
        <ListItem sx={{ backgroundColor: "primary.main", m: "0!important" }}>
          <ListItemAvatar sx={{ mr: 2, display: "flex", alignItems: "center" }}>
            <IconRelaxed
              style={{
                filter: "drop-shadow(0px 0.4px 1.8px rgb(0 0 0 / 25%))",
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{ fontWeight: 600, fontSize: "1.25em", color: "white" }}
              >
                {t("report.actions.download_app")}
              </Typography>
            }
            secondary={
              <Typography sx={{ fontSize: "0.925em", color: "white" }}>
                {t("report.actions.insert_invitation")}
              </Typography>
            }
          />
        </ListItem>
      )}
      <Grid
        container
        spacing={4}
        columns={2}
        sx={{ p: 3, textAlign: "center" }}
      >
        <Grid item xs={2} sm={1}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: 2,
            }}
          >
            <a
              style={{ flexGrow: 1 }}
              href={qr_apple}
              target="_blank"
              rel="noreferrer"
            >
              <QRCodeSVG
                value={qr_apple}
                size={128}
                style={{ maxWidth: "100%" }}
              />
            </a>
            <a
              style={{ flexGrow: 1 }}
              href={qr_google}
              target="_blank"
              rel="noreferrer"
            >
              <QRCodeSVG
                value={qr_google}
                size={128}
                style={{ maxWidth: "100%" }}
              />
            </a>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              justifyItems: "center",
              gap: 2,
              svg: { maxWidth: "100%", py: 1, maxHeight: 52 },
            }}
          >
            <a
              href={qr_apple}
              target="_blank"
              style={{ width: "100%" }}
              rel="noreferrer"
            >
              <DownloadApple style={{}} />
            </a>
            <a
              href={qr_google}
              target="_blank"
              style={{ width: "100%" }}
              rel="noreferrer"
            >
              <DownloadAndroid style={{}} />
            </a>
          </Box>
        </Grid>

        {showSmUp && (
          <Grid item xs={1} sx={{ textAlign: "center", color: "primary.main" }}>
            <Box
              sx={{
                py: 2,
                borderRadius: 2,
                bgcolor: "background.default",
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: "0.925em" }}>
                {t("report.actions.your_invitation_code")}:
              </Typography>
              <Typography sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                {invitation_code}
              </Typography>
            </Box>
            <Box sx={{ py: 2, borderRadius: 2, bgcolor: "background.default" }}>
              <Typography sx={{ fontSize: "0.925em" }}>
                {t("report.actions.your_family_invitation_code")}:
              </Typography>
              <Typography sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                {family_invitation_code}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default function SectionAction(codes: InvitationCodeProps) {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const print = useMediaQuery("print");

  // The default is always the environment variable
  // Note we are using the opposite logic here, because the env defines if it is hidden/disabled
  // We want the query to pass a positive value, to avoid showing the user that they are missing something
  // I.e. show_kyan = !is_kyan_content_hidden
  let show_kyan =
    process.env.REACT_APP_KYAN_CONTENT_HIDDEN?.toLowerCase() !== "true";
  // I.e. code_enabled = !is_code_disabled
  let code_enabled = process.env.REACT_APP_INVITATION_CODE_DISABLED !== "true";

  // If the query parameter is present, we use that value
  // Avoid using a name that could give a bad impression to the user
  // E.g. "show_kyan" could imply not showing kyan content is an option
  //   if (searchParams.has("with_app")) {
  if (print) {
    show_kyan = searchParams.get("with_app") === "true";
  }

  // We know that if "invitation_code" is being passed explicitly, we should show the codes
  //   if (searchParams.has("invitation_code")) {
  if (print) {
    code_enabled = searchParams.has("invitation_code");
  }

  return (
    <SectionContainer title={t("report.actions.title")}>
      <Typography sx={{ fontSize: "1.25em" }}>
        {t("report.actions.to_consider")}
      </Typography>
      <Typography
        sx={{
          pl: 2,
          li: {
            mt: 4,
          },
        }}
        component="ol"
      >
        {show_kyan && <li>{t("report.actions.daily_habit_with_app")}</li>}
        {code_enabled && <InvitationCard {...codes} />}
        {/* {!is_kyan_content_hidden && (
          <li>{t("report.actions.daily_habit_with_app")}</li>
        )}
        {!is_code_disabled && <InvitationCard {...codes} />} */}
        <li>{t("report.actions.be_aware")}</li>
        <li>{t("report.actions.try_to_relax")}</li>
        <li>{t("report.actions.leave_work_behind")}</li>
      </Typography>
    </SectionContainer>
  );
}
