import React, { useEffect, useState } from "react";

import image_aws from "assets/aws-logo.png";
import image_mbi from "assets/mbi-logo.png";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as IconRelaxed } from "assets/icon-relaxed.svg";

import { useTranslation } from "react-i18next";

import {
  alpha,
  Box,
  Button,
  Container,
  Divider,
  keyframes,
  LinearProgress,
  Portal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ContentPasteGo, SaveAlt } from "@mui/icons-material";

import { ReactComponent as CircleWavesIcon } from "assets/circle-quarter.svg";
import Mbi from "models/Mbi";
import Aws from "models/Aws";
import { getSurveyResultByUserId } from "database/firestore";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { endOfDay, addDays, isBefore } from "date-fns";
import SectionBurnout from "./SectionBurnout";
import SectionAbout from "./SectionAbout";
import SectionAction from "./SectionActions";
import SummaryAws from "./SummaryAws";
import SummaryMbi from "./SummaryMbi";

const breath = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
`;

function LogoPrint() {
  return (
    <Box
      sx={{
        position: "relative",
        mx: -3,
      }}
    >
      <Box sx={{ pageBreakBefore: "always" }} />
      <Box
        sx={{
          width: 260,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Logo />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Box sx={{ height: 90, mt: 4, mb: 2 }}>
          <img
            height={90}
            src={image_mbi}
            alt="MBI Logo"
            style={{ marginLeft: "-14px" }}
          />
          <img
            height={90}
            src={image_aws}
            alt="AWS Logo"
            style={{ marginLeft: "-14px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function GeneratingReport() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "fixed", top: 0, left: 0, right: 0 }}>
        <LinearProgress sx={{ animationDuration: 3 }} />
      </Box>

      <Box sx={{ width: 72, animation: `${breath} 2s ease-in-out infinite` }}>
        <IconRelaxed
          style={{ filter: "drop-shadow(0px 0px 25px rgb(0 0 0 / 15%))" }}
        />
      </Box>
      <Typography sx={{ color: "primary.dark", fontWeight: 500, mt: 2 }}>
        {t("report.generating")}
      </Typography>
    </Container>
  );
}

export default function Report() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const print = useMediaQuery("print");
  const [profiles, setProfiles] = useState<{ mbi: Mbi; aws: Aws }>();
  const [searchParams] = useSearchParams();

  const { token } = useParams();

  if (!token) {
    return <Box>Invalid token</Box>;
  }

  // We have the issue of the invitation_code and family_invitation_code being
  // set as env variables, but when printing the report is created in a different environment
  // Each instance will show their environment variables, but give priority to the query params
  const codes = {
    invitation_code:
      searchParams.get("invitation_code") ??
      process.env.REACT_APP_INVITATION_CODE,
    family_invitation_code:
      searchParams.get("family_code") ??
      process.env.REACT_APP_FAMILY_INVITATION_CODE,
  };

  // When creating the download URL, the environment variables have priority, because the
  // report should reflect the environment where the survey was taken
  // Note we are using the opposite logic here, if it hasn't explicitly been hidden/disabled, it is enabled
  const with_codes = process.env.REACT_APP_INVITATION_CODE_DISABLED !== "true";
  const with_app = process.env.REACT_APP_KYAN_CONTENT_HIDDEN !== "true";

  // Build the download URL for the report
  // The URL is built with the token provided by the user, and the required env variables
  let download_url = `${process.env.REACT_APP_CLOUD_FUNCTION_DOWNLOAD_REPORT_URL}${token}`;

  const queryParams = [];

  if (with_codes) {
    queryParams.push(`invitation_code=${codes.invitation_code}`);
    queryParams.push(`family_code=${codes.family_invitation_code}`);
  }

  if (with_app) {
    queryParams.push(`with_app=true`);
  }

  if (queryParams.length > 0) {
    download_url += `?${queryParams.join("&")}`;
  }

  useEffect(() => {
    const getData = async () => {
      let mean_values;

      try {
        const data = await getSurveyResultByUserId(token);

        i18n.changeLanguage(data.locale);

        const today = new Date();
        const created_at = data?.created_at.toDate();
        const end_of_next_day = endOfDay(addDays(created_at, 1));
        const is_token_valid = isBefore(today, end_of_next_day);

        mean_values = (is_token_valid && data?.mean_values) || {};
      } catch (error) {
        mean_values = {};
      }

      // For testing purposes
      if (token === "ka5Q0nOuSlxQSGWgboSmj5sGvoAG") {
        // profile - ineffective (percent and z aren't exact values due to rounding)
        mean_values = {
          cynicism: 1.6, // percent: 60 (z: 0.16)
          professional_efficacy: 1.83, // percent: 10 (z: -2.24)
          exhaustion: 2.2, // percent: 60 (z: 0.03)
          workload: 3.4, // percent: 80 - average (z: 0.81)
          control: 1, // percent: 10 - mismatch (z: -2.56)
          reward: 2.75, // percent: 40 - average (z: -0.5)
          community: 2.4, // percent: 20 - mismatch (z: -1.25)
          fairness: 2.67, // percent: 60 - average (z: 0.15)
          values: 2, // percent: 10 - mismatch (z: -1.5)
        };
      }

      const MbiProfile = new Mbi({ mean_values });
      const AwsProfile = new Aws({ mean_values });

      setProfiles({
        mbi: MbiProfile,
        aws: AwsProfile,
      });
    };

    getData();
  }, [token]);

  if (!profiles) {
    return <GeneratingReport />;
  }

  if (profiles && (!profiles?.mbi.isValid() || !profiles?.aws.isValid())) {
    return (
      <Container
        id="report"
        sx={{
          px: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            position: print ? "relative" : undefined,
          }}
        >
          <Portal container={document.body}>
            <Box
              sx={{
                width: print ? 260 : { xs: 190, md: 260, lg: 300 },
                position: "absolute",
                left: { xs: 16, md: 0 },
                top: 0,
                zIndex: -1,
              }}
            >
              <Logo />
            </Box>
          </Portal>
        </Box>
        <Box
          sx={{
            backgroundColor: alpha("#ffffff", 0.9),
            backdropFilter: "blur(6px)",
            p: 4,
            borderRadius: 4,
            maxWidth: "sm",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ color: "primary.dark", fontWeight: 500 }}>
            {t("report.unavailable")}
          </Typography>
          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant="outlined"
            startIcon={<ContentPasteGo />}
            onClick={() => navigate("/")}
          >
            {t("report.go_to_survey")}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container id="report" sx={{ px: { xs: 0, sm: 3 }, pb: print ? 0 : 12 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          position: print ? "relative" : undefined,
        }}
      >
        {!print && (
          <>
            <Portal container={document.body}>
              <Box
                sx={{
                  width: { xs: 190, md: 260, lg: 300 },
                  position: "absolute",
                  left: { xs: 16, md: 0 },
                  top: 0,
                  zIndex: -1,
                }}
              >
                <Logo />
              </Box>
            </Portal>
            <Box sx={{ flex: 1 }} />

            <Box
              sx={{
                height: { xs: 70, md: 85, lg: 95 },
                mt: { xs: 1.5, md: 3, lg: 4 },
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <img
                height="100%"
                src={image_mbi}
                alt="MBI Logo"
                style={{ marginLeft: "-14px" }}
              />
              <img
                height="100%"
                src={image_aws}
                alt="AWS Logo"
                style={{ marginLeft: "-14px" }}
              />
            </Box>
          </>
        )}
      </Box>

      {!print && (
        <Box sx={{ my: 2, mr: 1, textAlign: "right" }}>
          <Button
            size="large"
            href={`${download_url}`}
            target="_blank"
            startIcon={<SaveAlt />}
          >
            {t("common.download")}
          </Button>
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: alpha("#ffffff", 0.9),
          backdropFilter: "blur(6px)",
          px: { xs: 2, sm: 4 },
          borderRadius: 10,
        }}
      >
        {print && <LogoPrint />}

        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.95em",
            color: "primary.main",
            pt: 7,
          }}
        >
          {t("report.thank_you")}
        </Typography>

        {profiles && profiles?.mbi.isValid() && (
          <SummaryMbi profile={profiles.mbi} />
        )}
        {profiles && profiles?.aws.isValid() && (
          <SummaryAws profile={profiles.aws} />
        )}

        {!print && <Divider sx={{ mt: [4, 4, 7] }} />}

        {print && <LogoPrint />}

        <SectionAction {...codes} />
        <SectionAbout />
        <SectionBurnout />

        <Divider sx={{ mt: [4, 4, 7] }} />

        <Box
          sx={{
            color: (theme) => alpha(theme.palette.primary.main, 0.7),
            py: 3,
            textAlign: "center",
            fontSize: "0.825em",
            pageBreakAfter: "auto",
          }}
        >
          <span>{t("report.copyright")}</span>
          <br />
          <span>{t("report.published_by")}</span>
        </Box>
      </Box>

      {!print && (
        <Box
          sx={{
            color: "secondary.main",
            position: "absolute",
            zIndex: -1,
            bottom: -8,
            right: {
              sm: -60,
            },
            left: {
              xs: 60,
              sm: "auto",
            },
            width: "500px",
          }}
        >
          <CircleWavesIcon />
        </Box>
      )}
    </Container>
  );
}
